import {
  addHours,
  getHours,
  getMinutes,
  getSeconds,
  format,
  parseISO,
} from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

// eslint-disable-next-line import/prefer-default-export
export const convertToDuration = (secondsAmount: number): any => {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time); // eslint-disable-line

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  return `${hours}:${minutes}:${seconds}`;
};

export const formattedDateOnlyDayMonth = (value: any): string => format(parseISO(value), 'dd MMMM', { locale: ptBr });

export const formattedDate = (value: any, mask?: string): string => value && format(parseISO(value), mask ?? 'dd MMM', { locale: ptBr });

export const formattedDateAndTime = (value: any): string => value && format(parseISO(value), 'dd/MM/yyyy HH:mm', { locale: ptBr });
